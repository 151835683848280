import React from 'react'
import Layout from '../components/Layout'

export default function PageNotFound() {
    return (
        <Layout title="Katharina Kohler | Page not found" description="404 page not found!"> 
            Lost?
        </Layout>
    )
}
